<template>
  <moe-page title="商品推荐">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="font-20 fwb">商品推荐</div>
        <div class="font-14">添加的商品将在该商品详情”云宠推荐“展示,仅展示前六个上架中的商品。若设置商品不足6个，则系统随机推荐“猜你喜欢“商品池补充。未设置推荐商品的，系统推荐不生效。</div>
      </template>

      <moe-table :data="goodsRecommendParams.itemList" :numberShow="false" :showPage="false" :mode="false">
        <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">添加商品</el-button>
        <el-table-column label="商品信息" min-width="200">
          <template slot-scope="{ row }">
            <div class="df aic jcc">
              <moe-image :src="row.coverUrl" width="80px" height="80px" />
              <div class="df1 tal ml-10">
                <p class="fwb">{{ row.name }}</p>
                <p class="font-12 color-info">商品编号: {{ row.recommendGoodsId }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="销售价" min-width="150" :formatter="formatterSalePrice" />
        <el-table-column prop="stock" label="库存" min-width="80" />
        <el-table-column label="操作" min-width="350" fixed="right">
          <div class="moe-table_btns df aic jcc" slot-scope="{ row, $index }">
            <template v-if="$index > 0">
              <el-button type="success" size="mini" icon="el-icon-upload2" @click="handleClick('topping', row, $index)">置顶</el-button>
              <el-button type="primary" size="mini" icon="el-icon-top" @click="handleClick('top', row, $index)">上移</el-button>
            </template>
            <el-button v-if="$index < (goodsRecommendParams.itemList.length - 1)" type="primary" size="mini" icon="el-icon-bottom" @click="handleClick('bottom', row, $index)">下移</el-button>
            <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleClick('delete', row, $index)">移除</el-button>
          </div>
        </el-table-column>
      </moe-table>
      <div class="df aic jcc mt-20">
        <el-button type="info" icon="el-icon-close" @click="$moe_coordinator.navigateBack()">关闭</el-button>
        <el-button type="primary" icon="el-icon-upload2" :loading="goodsRecommendLoad" @click="req_updateGoodsRecommendList()">{{ goodsRecommendLoad ? '保存中...' : '提交' }}</el-button>
      </div>
    </moe-card>

    <goodsDialog
      :showDialog.sync="showDialog"
      :defaultSelectIds="goodsRecommendParams.itemList && goodsRecommendParams.itemList.length ? goodsRecommendParams.itemList.map(({ recommendGoodsId }) => recommendGoodsId) : []"
      @close="handleClose">
    </goodsDialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
export default {
  name: 'GoodsRecommend',
  components: {
    goodsDialog
  },
  data() {
    return {
      goodsRecommendParams: {
        goodsId: '',
        itemList: []
      },
      showDialog: false,
      goodsRecommendLoad: false
    }
  },
  methods: {
    formatterSalePrice({ minPrice, maxPrice }) {
      return `¥ ${minPrice}${this.$moe_math.mathGreaterThan(maxPrice, minPrice) ? ` - ¥ ${maxPrice}` : ''}`
    },
    handleClose(selectList) {
      selectList.forEach((item) => {
        let findItem = this.goodsRecommendParams.itemList.find(({ recommendGoodsId }) => item.id === recommendGoodsId);
        if (!findItem) {
          this.goodsRecommendParams.itemList.push({
            ...item,
            recommendGoodsId: item.id,
            id: ''
          });
        }
      })

      this.showDialog = false;
    },
    handleClick(type, { name }, index) {
      let { itemList } = this.goodsRecommendParams
      switch (type) {
        case 'delete':
          this.$moe_layer.confirm(`您确定要移除当前选项"${name}"吗？`, () => {
            itemList.splice(index, 1);
            this.$moe_msg.success('移除成功,提交后更新');
          });
          break;
        case 'topping':
          this.$moe_layer.confirm(`您确定要置顶当前选项"${name}"吗？`, () => {
            const row = this.$moe_lodash.cloneDeepData(itemList[index]);
            itemList.splice(index, 1);
            itemList.unshift(row);
            this.$moe_msg.success('置顶成功,提交后更新');
          });
          break;
        case 'top':
          this.$moe_layer.confirm(`您确定要上移当前选项"${name}"吗？`, () => {
            itemList.splice(index - 1, 2, itemList[index], itemList[index - 1]);
            this.$moe_msg.success('上移成功,提交后更新');
          });
          break;
        case 'bottom':
          this.$moe_layer.confirm(`您确定要下移当前选项"${name}"吗？`, () => {
            itemList.splice(index, 2, itemList[index + 1], itemList[index]);
            this.$moe_msg.success('下移成功,提交后更新');
          });
          break;
      }
    },
    /** 获取商品推荐列表 */
    async req_getGoodsRecommendList() {
      const { goodsId } = this.goodsRecommendParams;
      const { code, result, message } = await this.$moe_api.GOODS_API.getGoodsRecommendList({ goodsId });
      if (code === 200) {
        if (result.list.length) {
          this.req_getQueryShopGoodsByIds(result.list);
        }
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 根据商品ID获取商品列表 */
    async req_getQueryShopGoodsByIds(goodsList) {
      const { code, result, message } = await this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: goodsList.map(({ recommendGoodsId }) => recommendGoodsId).join(',') })
      if (code === 200) {
        this.goodsRecommendParams.itemList = goodsList.map(({ id, recommendGoodsId, sort }) => {
          let findItem = result.find(({ id }) => recommendGoodsId === id);
          return {
            ...findItem,
            id,
            recommendGoodsId,
            sort,
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 更新商品推荐活动信息 */
    async req_updateGoodsRecommendList() {
      this.goodsRecommendLoad = true;
      let arrayLength = this.goodsRecommendParams.itemList.length - 1;
      const { code, message } = await this.$moe_api.GOODS_API.updateGoodsRecommendList({
        ...this.goodsRecommendParams,
        itemList: this.goodsRecommendParams.itemList.map(({ id, recommendGoodsId }, index) => {
          return {
            id: id || '',
            recommendGoodsId,
            sort: arrayLength - index,
          }
        })
      });
      if (code === 200) {
        this.$moe_msg.success('操作成功', {
          completionHandler: () => {
            this.$moe_coordinator.navigateBack();
          }
        });
      } else {
        this.$moe_msg.error(message);
      }
      this.goodsRecommendLoad = false;
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.goodsRecommendParams.goodsId = this.$route.query.id;
      this.req_getGoodsRecommendList();
    }
  }
}
</script>

<style lang="scss" scoped>

</style>